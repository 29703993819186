// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-but-why-js": () => import("./../../../src/pages/but-why.js" /* webpackChunkName: "component---src-pages-but-why-js" */),
  "component---src-pages-cant-see-js": () => import("./../../../src/pages/cant-see.js" /* webpackChunkName: "component---src-pages-cant-see-js" */),
  "component---src-pages-celebrand-speaks-js": () => import("./../../../src/pages/celebrand-speaks.js" /* webpackChunkName: "component---src-pages-celebrand-speaks-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-couches-js": () => import("./../../../src/pages/couches.js" /* webpackChunkName: "component---src-pages-couches-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-team-js": () => import("./../../../src/pages/join-the-team.js" /* webpackChunkName: "component---src-pages-join-the-team-js" */),
  "component---src-pages-long-story-short-js": () => import("./../../../src/pages/long-story-short.js" /* webpackChunkName: "component---src-pages-long-story-short-js" */),
  "component---src-pages-look-at-a-duck-js": () => import("./../../../src/pages/look-at-a-duck.js" /* webpackChunkName: "component---src-pages-look-at-a-duck-js" */),
  "component---src-pages-pain-form-js": () => import("./../../../src/pages/pain-form.js" /* webpackChunkName: "component---src-pages-pain-form-js" */),
  "component---src-pages-pain-js": () => import("./../../../src/pages/pain.js" /* webpackChunkName: "component---src-pages-pain-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolios-strapi-portfolios-name-js": () => import("./../../../src/pages/portfolios/{StrapiPortfolios.name}.js" /* webpackChunkName: "component---src-pages-portfolios-strapi-portfolios-name-js" */),
  "component---src-pages-service-form-js": () => import("./../../../src/pages/service-form.js" /* webpackChunkName: "component---src-pages-service-form-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

